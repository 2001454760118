import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import {
  ACCOUNT_DETAILS_ROUTE,
  AFFILIATE_ROUTE,
  BUSINESS_LANDING_PAGE_ROUTE,
  COUNTRY_MAP,
  DASHBOARD_ROUTE,
  HOME_ROUTE,
  LS_USER_ID,
  SS_USER_LOCATION,
} from "app/const/app-constant";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { BrainStyle } from "app/core/components/embedded-chat/embedded-chat.model";
import { PwaService } from "app/core/components/pwa-prompt/pwa.service";
import {isMobileDevice, isRegularUser, removeTokenForLogout} from "app/core/functions/helper-functions";
import {
  getMobileappSettingsInterface,
  isMobileSettingsInterfaceDefined,
} from "app/core/modules/mobile-interfaces/app-device-assistant-interface";
import { LanguageService } from "app/language.service";
import { MyBrainPlans, UserData } from "app/pages/home/home.model";
import { UsersService } from "app/services/users.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatRadioModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    MatIconModule,
    MatBottomSheetModule,
    MatTooltipModule,
  ],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PwaService],
})
export class HeaderComponent extends BaseHttpComponent implements OnInit {
  userData: UserData | null = null;
  iconName = "";
  selectedLanguageFlag = "us";
  selectedLanguageCode = "en_gb";
  sidebarActive = false;
  showSettings = false;
  @Input() showOnlyLanguage = false;
  @Input() customizeColors? = false;
  @Input() customizeColorValues?: BrainStyle;
  @Input() isSharedBrain = false;

  languages = [
    { code: "en_gb", label: "English", flag: "us" },
    { code: "es_es", label: "Spanish", flag: "es" },
    { code: "de_de", label: "German", flag: "de" },
    { code: "ar_ar", label: "Qatar", flag: "ar" },
    { code: "fr_fr", label: "French", flag: "fr" },
    { code: "ko_ko", label: "Korean", flag: "ko" },
    { code: "zh_cn", label: "Chinese", flag: "ch" },
  ];

  constructor(
    private translocoService: TranslocoService,
    private languageService: LanguageService,
    private httpClient: HttpClient,
    private pwaService: PwaService,
    private userService: UsersService,
  ) {
    super();
  }

  ngOnInit(): void {
    const userId = localStorage.getItem(LS_USER_ID);
    if (userId) {
      this.userService.get(userId).subscribe((resp) => {
        if (resp && resp.isSuccess && resp.data) {
          AppStore.userData$.next(resp.data);
        } else if (!resp.isSuccess && resp.status == 404) { //logout if user not found
          AppStore.logout$.next(null);
        }
      });
    }

    this.subs$.add(
      AppStore.userData$
        .subscribe((resp) => {
          if (resp && resp) {
            resp.email = resp.email || "";
            resp.fullName = resp.email.split("@")[0]?.charAt(0) + resp.email.split("@")[1]?.charAt(0);
            this.userData = resp;
            if (resp.firstName && resp.lastName) {
              const firstNameInitial = resp.firstName.charAt(0);
              const lastNameInitial = resp.lastName.charAt(0);
              this.iconName = `${firstNameInitial}${lastNameInitial}`;
            } else {
              this.iconName = resp.fullName;
            }
            if (!this.isSharedBrain) {
              this.updateSelectedLanguage(resp.selectedLanguage);
            }
          }
        }),
    );

    this.subs$.add(
      AppStore.sidebarActive$.subscribe((status) => {
        this.sidebarActive = status;
        this.cdr.detectChanges();
      }),
    );

    this.subs$.add(
      AppStore.logout$.subscribe(() => {
        removeTokenForLogout();
        this.userData = null;
        AppStore.sidebarActive$.next(false);
        AppStore.footerActive$.next(true);
        AppStore.selectedBrainId$.next(null);
        AppStore.brainProjects$.next([]);
        AppStore.selectedBrain$.next(null);
        if (this.router.url.startsWith("/business")) {
          this.router.navigate([BUSINESS_LANDING_PAGE_ROUTE]);
        } else {
          this.router.navigate([HOME_ROUTE]);
        }
      }),
    );

    this.updateLocation();
    this.showSettings = isMobileSettingsInterfaceDefined();
  }

  changeLanguage(language: string) {
    if (this.userData) {
      this.userData.selectedLanguage = language;
      this.userService.update(this.userData).subscribe((resp) => {
        if (resp && resp.isSuccess && resp.data) {
          this.userData = resp.data;
          AppStore.userData$.next(this.userData);
        }
      });
    }
    this.updateSelectedLanguage(language);
    this.cdr.markForCheck();
  }
  isRouteStartsWithBrain(): boolean {
    const currentRoute = this.router.url; // Get the current route
    return currentRoute.startsWith("/brain") || currentRoute.startsWith("/content"); // Check if it starts with "/brain"
  }

  async updateLocation() {
    const userId = localStorage.getItem(LS_USER_ID);
    const userLocation = sessionStorage.getItem(SS_USER_LOCATION);
    if (!userId || !userLocation) {
      const data = await firstValueFrom(this.httpClient.get<any>("https://api.country.is/"));
      if (!data) return;

      // country is a two-letter iso code
      const language = COUNTRY_MAP[data.country as keyof typeof COUNTRY_MAP] || "en_gb";
      if (!userId) this.updateSelectedLanguage(language);
      if (!userLocation) sessionStorage.setItem(SS_USER_LOCATION, data.country);
    }
  }

  updateSelectedLanguage(language: string) {
    const selectedLanguage = this.languages.find((lang) => lang.code === language);
    if (selectedLanguage) {
      this.translocoService.setActiveLang(language);
      AppStore.selectedLanguage$.next(language);
      this.selectedLanguageFlag = selectedLanguage.flag;
      this.selectedLanguageCode = language;
      this.languageService.setSelectedLanguage(language);
    }
  }

  navigateToAccountDetails() {
    this.router.navigate([ACCOUNT_DETAILS_ROUTE]);
  }

  triggerPwa() {
    if (isMobileDevice()) {
      this.pwaService.initPwaPrompt();
    }
  }

  checkIfInstalled() {
    return this.pwaService.isAppInstalled;
  }

  logout() {
    AppStore.logout$.next(null);
  }
  openSidebar() {
    const currentValue = AppStore.sidebarActive$.getValue();
    AppStore.sidebarActive$.next(!currentValue);
  }

  backToHomepage() {
    this.router.navigate([DASHBOARD_ROUTE]);
    AppStore.selectedBrainId$.next(null);
    AppStore.selectedBrain$.next(null);
    this.cdr.markForCheck();
  }

  openNativeSettings() {
    getMobileappSettingsInterface().onSettingsClick();
  }

  protected readonly isMobileDevice = isMobileDevice;
  protected readonly AFFILIATE_ROUTE = AFFILIATE_ROUTE;
  protected readonly isRegularUser = isRegularUser;
}
