export const environment = {
  environmentName: "development",
  apiBaseUrlAI: "https://brain-assistant-embedding-api.dev.eu2.rezolve.com", //use this for local http://127.0.0.1:5000, docker: http://127.0.0.1:3000, https://brain-assistant-embedding-api.dev.eu2.rezolve.com/
  apiKey: "098a9f3104ae508bf2fb695abc875fab",
  socketUrl: "https://api.speech.brain-demo.demo.eu.rezolve.com",
  baseUrl: "https://dev.mybrain.zone", //use this for local http://localhost:4200
  googleAnalytics: "",
  allowedFiles: ["pdf", "doc", "docx", "odt", "epub", "pages", "pptx", "xls", "xlsx", "numbers", "csv", "txt"],
  brainHelpId: "344e1cb6-5267-4b78-8cc7-3ecfd37f110f",
  pineconeIndexId: "index-my-brain-1",
  pineconeIndexName: "index-my-brain-1", // [TODO] get from database
  isPlanOnSale: false,
  dotNetBaseUrl: "https://brain-assistant-api.dev.eu2.rezolve.com", // when running .net core locally: http://localhost:5001, https://brain-assistant-api.dev.eu2.rezolve.com/
  s3EndpointUrl: "https://engagement.dev.eu2.rezolve.com/file/upload",
  featureFlags: {
    personaSelect: true,
  }
};
