export enum EmbeddingFileExtension {
  pdf = "pdf",
  doc = "doc",
  docx = "docx",
  odt = "odt",
  epub = "epub",
  pages = "pages",
  pptx = "pptx",
  xls = "xls",
  xlsx = "xlsx",
  numbers = "numbers",
  csv = "csv",
  txt = "txt",
}

export interface GenericBAResponse<T> {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  results: T[];
}

export interface EmbeddingFile {
  id?: string;
  awsFile?: string;
  complete?: boolean;
  contentType?: BrainContentType;
  projectId?: string;
  errorMessage?: string;
  failed?: boolean;
  failedInt?: number;
  file?: string;
  fileExtension?: EmbeddingFileExtension;
  fileName?: string;
  fileSize?: number;
  metadata?: Metadata;
  status?: EmbeddingFileStatus;
  suggestedQuestions?: string[];
  webpage?: string;
  webpageSync?: string;
  createdBy?: string;
  isDeleting?: boolean;
}

export interface Metadata {
  author: string;
  image: string;
  link?: string;
  published: string;
  section: string;
  title: string;
}

export enum EmbeddingFileStatus {
  None,
  Completed,
  Failed,
  Indexing,
  Processing,
  Valid,
  Uploading,
}

export enum StatusQueryType {
  All,
  Complete,
  Incomplete,
}

export enum BrainContentType {
  None,
  File,
  Link,
  Website,
}

export enum CrawlWebSiteStatus {
  processing = "processing",
  canceled = "canceled",
  finished = "finished",
  complete = "complete",
  failed = "failed",
}

export interface BubblePostResp {
  id: string;
}

export interface WebAddressPostResp {
  title: string;
  valid: string;
  url?: string;
}

export enum LoginScreen {
  enterEmail,
  verifyEmailOtp,
}

export type KeyValueBooleanObject<T extends string | number> = {
  [key in T]: boolean;
};

export enum InternetSearchStatus {
  searching = "searching",
  analyzing = "analyzing",
}

export interface CheckoutSessionRequest {
  userId: string;
  priceId: string;
  priceName: string;
}

export interface FileUploadResponse {
  url: string;
  size: number;
  contentType: string;
  name: string;
}

export interface FileCreationRequest {
  userId: string;
  projectId: string;
  fileData?: FileUploadResponse;
}
