/* eslint-disable max-lines */
import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, NO_ERRORS_SCHEMA, OnInit, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { Title } from "@angular/platform-browser";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { UserLimitComponent } from "app/core/components/user-limit/user-limit.component";
import { getFormattedBrainName, replaceParamsWithValue } from "app/core/functions/helper-functions";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { UserData, UserDataLimits } from "app/pages/home/home.model";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { UpdateTitle } from "app/services/update-title.service";
import { UsersService } from "app/services/users.service";
import { BrainContentType, EmbeddingFile } from "app/app.model";
import {
  CONTENT_ROUTE_ID,
  IS_LIMIT_VISIBLE,
  MY_BRAIN_ROUTE,
  ProcessingEmbeddings,
  UsedEmbeddings
} from "app/const/app-constant";
import { mergeMap, of } from "rxjs";
import { ConfirmDialogComponent, ConfirmDialogOption } from "../confirm-dialog/confirm-dialog.component";
import { FileUploadComponent } from "../file-upload/file-upload.component";
import { WebPageUploadComponent } from "../web-page-upload/web-page-upload.component";
import { AddMyBrainContentService } from "./add-my-brain-content.service";

@Component({
  selector: "app-add-my-brain-content",
  standalone: true,
  imports: [
    MatDialogModule,
    WebPageUploadComponent,
    TranslocoModule,
    FormsModule,
    FileUploadComponent,
    MatButtonModule,
    ConfirmDialogComponent,
    MatIconModule,
    UserLimitComponent,
    MatExpansionModule,
    NgIf,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: "./add-my-brain-content.component.html",
  styleUrls: ["./add-my-brain-content.component.scss"],
  providers: [AddMyBrainContentService],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddMyBrainContentComponent extends BaseHttpComponent implements OnInit {
  @ViewChild(WebPageUploadComponent) webPageUploadComponent!: WebPageUploadComponent;
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;

  contents: EmbeddingFile[] = [];
  selectedBrain = AppStore.selectedBrain$.value;
  isUploading = false;
  deleteInProgress = false;
  isConversationPage = false;
  isManagedContentPage = false;
  userDataLimits!: UserDataLimits;
  selectedBrains?: Brain[];
  isLimitVisible!: boolean;
  isIndexing = false;
  originalTitle = "";
  userData?: UserData;

  set isHidden(value: boolean) {
    if (this.isLimitVisible !== value) {
      this.isLimitVisible = value;
      const expiration = Date.now() + 24 * 60 * 60 * 1000;
      localStorage.setItem(IS_LIMIT_VISIBLE, JSON.stringify({ value, expiration }));
    }
  }

  get isHidden(): boolean {
    return this.isLimitVisible;
  }

  constructor(
    private addContentService: AddMyBrainContentService,
    private dialogRef: MatDialogRef<AddMyBrainContentComponent>,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private embeddingFileService: EmbeddingFileService,
    private embeddingProjectService: EmbeddingProjectsService,
    private userService: UsersService,
    private titleService: UpdateTitle,
    private title: Title,
  ) {
    super();
  }

  isUploadingEvent(event: boolean) {
    this.isUploading = event;
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.originalTitle = this.title.getTitle();
    this.titleService.translatePageTitle("addContent");
    this.deleteInProgress = false;

    this.subs$.add(
      AppStore.userData$.subscribe((res) => {
        if (res) {
          this.userData = res;
        }
      }),
    );

    if (this.router.url.includes("/dashboard")) {
      if (this.userData) {
        this.addContentService.createBrain(this.userData).subscribe((brain) => {
          this.selectedBrain = brain;
        });
      }
    } else {
      this.subs$.add(
        AppStore.selectedBrain$.subscribe((brain) => {
          this.selectedBrain = brain;
          if (this.router.url.includes("/content")) {
            if (
              this.router.url.substring(1) ===
                replaceParamsWithValue(CONTENT_ROUTE_ID, { id: (brain as Brain)?.id, tab: UsedEmbeddings }) ||
              this.router.url.substring(1) ===
                replaceParamsWithValue(CONTENT_ROUTE_ID, { id: (brain as Brain)?.id, tab: ProcessingEmbeddings })
            ) {
              this.isConversationPage = false;
              this.isManagedContentPage = true;
            }
          }

          if (this.router.url.substring(1) === replaceParamsWithValue(MY_BRAIN_ROUTE, { id: (brain as Brain)?.id })) {
            this.isConversationPage = true;
            this.isManagedContentPage = false;
          }

          this.cdr.markForCheck();
        }),
      );
    }

    this.subs$.add(
      AppStore.userDataLimits$.subscribe((resp) => {
        if (resp) {
          this.userDataLimits = resp as UserDataLimits;
          this.isLimitVisible = resp.isContentExceeded || resp.isQuestionExceeded;
          this.isHidden = this.isLimitVisible;
        }
      }),
    );

    AppStore.brainProjects$.subscribe((projects) => {
      this.selectedBrains = projects;
    });

    AppStore.isIndexing$.subscribe((isIndexing) => {
      this.isIndexing = isIndexing;
    });
  }

  updateIsHidden(newValue: boolean) {
    const expiration = Date.now() + 24 * 60 * 60 * 1000;
    localStorage.setItem(IS_LIMIT_VISIBLE, JSON.stringify({ value: newValue, expiration }));
  }

  onWebBrainContents(webBrainContents: EmbeddingFile[]) {
    this.contents = [
      ...this.contents.filter((c) => c.contentType === BrainContentType.File && !c.failed),
      ...webBrainContents.filter((c) => !c.failed),
    ];
    this.cdr.markForCheck();
  }

  onFileBrainContents(fileBrainContents: EmbeddingFile[]) {
    this.contents = [
      ...this.contents.filter(
        (c) => ((c.contentType === BrainContentType.Link || c.contentType === BrainContentType.Website) && !c.failed),
      ),
      ...fileBrainContents.filter((c) => !c.failed),
    ];
    this.cdr.markForCheck();
  }

  addContent() {
    this.dialogRef.close();
    this.setBrainName(this.contents[0]);
    AppStore.addedBrainContents$.next([]);
    if (this.router.url.includes("/content")) {
      AppStore.selectedBrain$.next(this.selectedBrain);
      return;
    } else {
      AppStore.isRedirectedFromMain$.next(true);
      this.router.navigate([replaceParamsWithValue(MY_BRAIN_ROUTE, { id: this.selectedBrain?.id as string })]);
      if (this.router.url.includes("/brain")) {
        if (this.selectedBrain) {
          this.embeddingProjectService.get(this.selectedBrain?.id).subscribe((res) => {
            if (res && res.isSuccess && res.data) {
              AppStore.selectedBrainId$.next(res.data?.id);
              this.selectedBrain = res.data;
              this.cdr.markForCheck();
            }
          });
        }
        return;
      }
    }
  }

  closeDialog() {
    if (!this.selectedBrain || this.deleteInProgress) return;

    this.deleteInProgress = true;
    this.cdr.markForCheck();
    if (this.contents.length > 0) {
      const ref = this.dialog.open(ConfirmDialogComponent, {
        panelClass: "scrollable-dialog",
      });
      ref.componentInstance.title = this.transloco.translate("leftSidebar.deleteLabel");
      ref.componentInstance.confirmBodyTxt = this.translocoService.translate("leftSidebar.confirmBodyTxt");
      ref.componentInstance.okButtonTxt = this.translocoService.translate("leftSidebar.yes");
      ref.componentInstance.cancelButtonTxt = this.translocoService.translate("leftSidebar.cancel");
      ref
        .afterClosed()
        .pipe(
          mergeMap((result) => {
            this.deleteInProgress = false;
            if (result === ConfirmDialogOption.ok) {
              this.dialogRef.close();
              this.deleteContent();
            }
            return of(null);
          }),
        )
        .subscribe();
    } else {
      if (this.isManagedContentPage || this.isConversationPage) {
        this.dialogRef.close();
        return;
      }
      const embeddingFilesIds = this.contents.map((x) => (x && x.id ? x.id : "")).filter((x) => x);
      this.embeddingProjectService
        .delete(this.selectedBrain.id, embeddingFilesIds)
        .pipe(
          mergeMap((res) => {
            if (res && res.isSuccess) {
              this.deleteInProgress = false;
              this.updateBrainsList();
              AppStore.selectedBrain$.next(null);
              this.cdr.markForCheck();
              return this.userService.get(this.selectedBrain?.createdBy);
            }

            return of(null);
          }),
        )
        .subscribe((res) => {
          if (res && res.isSuccess && res.data) {
            AppStore.userData$.next(res.data);
          }
        });
      this.dialogRef.close();
    }
  }

  deleteContent() {
    if (this.isConversationPage || this.isManagedContentPage) {
      this.embeddingFileService
        .batchDelete(this.contents)
        .pipe(
          mergeMap((result) => {
            if (result && result.isSuccess && result.data) {
              this.contents = [];
              AppStore.addedBrainContents$.next([]);
              this.cdr.markForCheck();
              return this.userService.get(this.selectedBrain?.createdBy);
            }
            return of(null);
          }),
        )
        .subscribe((response) => {
          if (response && response.isSuccess && response.data) {
            AppStore.userData$.next(response.data);
          }
        });
    } else {
      const embeddingFilesIds = this.contents.map((x) => (x && x.id ? x.id : "")).filter((x) => x);
      if (this.selectedBrain) {
        this.embeddingProjectService
          .delete(this.selectedBrain?.id, embeddingFilesIds)
          .pipe(
            mergeMap((res) => {
              if (res && res.isSuccess) {
                this.updateBrainsList();
                this.contents = [];
                AppStore.selectedBrain$.next(null);
                this.cdr.markForCheck();
                return this.userService.get(this.selectedBrain?.createdBy);
              }

              return of(null);
            }),
          )
          .subscribe((response) => {
            if (response && response.isSuccess && response.data) {
              AppStore.userData$.next(response.data);
            }
          });
      }
    }
  }

  updateBrainsList() {
    if (this.selectedBrains) {
      const filteredProjects = this.selectedBrains.filter((project) => project.id !== this.selectedBrain?.id);
      AppStore.brainProjects$.next(filteredProjects);
    }
  }

  setBrainName(firstFile: EmbeddingFile) {
    const newBrainName = getFormattedBrainName(
      firstFile?.metadata?.title || firstFile?.fileName || firstFile?.webpage || "",
    );

    // Skip if no brain is selected or name is already updated
    if (
      !this.selectedBrain || !newBrainName || this.selectedBrain?.projectName
    ) { return; }

    this.embeddingProjectService
      .get(this.selectedBrain.id)
      .pipe(
        mergeMap((result) => {
          if (result && result.isSuccess && result.data) {
            const brain = result.data;
            brain.projectName = newBrainName;
            return this.embeddingProjectService.update(brain);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((result) => {
        if (result && result.isSuccess && result.data) {
          this.selectedBrain = result.data;
          AppStore.selectedBrain$.next(result.data);
          this.cdr.markForCheck();
        }
      });
  }

  shouldCloseDialog() {
    this.dialogRef.close();
  }

  areAllFilesInErrorState() {
    return this.contents.every((x) => x.failed);
  }

  override ngOnDestroy(): void {
    this.title.setTitle(this.originalTitle);
  }
}
