<div *transloco="let t; read: 'addMyBrainContent'" class="flex w-[100%] h-[100%] flex-col">
  <div class="hidden lg:flex lg:min-h-[242px] h-full flex-col p-1">
    <div class="flex flex-row items-center mb-3">
      <i class="w-10 h-10 bg-light-violet rounded-full flex items-center justify-center mr-2 text-black-dark1">
        <img src="./assets/images/icons/globe-violet.svg" />
      </i>
      <span class="text-black-dark1 text-base">
        {{ t("addWebAddress") }}
      </span>
      <a href="https://help.rezolve.com/brain/assistant/content-guide/" class="ml-2" target="_blank">
        <img src="./assets/images/icons/question-mark-violet.svg" alt="" />
      </a>
    </div>
    <form>
      <div class="bg-light-violet px-3 pt-3 pb-8 rounded-lg min-h-[160px]">
        <div class="relative flex gap-2">
          <mat-form-field appearance="outline" class="h-8 w-full" *transloco="let v; read: 'validation'">
            <mat-label>{{ t("webAddress") }}</mat-label>
            <input matInput [formControl]="webPageUrl" name="webPageUrl" />
            <mat-error *ngIf="webPageUrl.invalid">{{ v("invalidUrl") }}</mat-error>
          </mat-form-field>
          <button
            class="border-none flex items-center justify-center w-[120px] h-[52px] text-violet-semi bg-violet-background rounded-lg text-base"
            brainLimitIndicator
            [limitConfig]="{
              isContentExceeded: userDataLimits.isContentExceeded
            }"
            [disabled]="userDataLimits.isContentExceeded || webPageUrl.invalid || uploading || fileUploading"
            (click)="validateUrl()"
          >
            <span class="inline-flex">
              <img src="./assets/images/icons/plus.svg" alt="" class="mr-2" />
              {{ t("add") }}
            </span>
          </button>
        </div>

        <div class="w-full mt-2" *ngIf="userData?.planLevel !== MyBrainPlans.free">
          <mat-checkbox [(ngModel)]="addAllPages" name="checkbox">
            <span class="text-rezolve-light-gray">{{ t("addAllPages") }}</span></mat-checkbox
          >
        </div>

        <div class="w-full mt-2" *ngIf="addAllPages">
          <span class="text-xs">{{ t("webScrapingWarning") }}</span>
        </div>
      </div>

      <div>
        <div class="flex justify-between px-2 py-2 text-light-black text-base">
          <div>
            <span>{{ t("webAddresses") }}</span>
          </div>
          <div>
            <span>{{ t("total") }}:</span> <b>{{ this.contents.length }}</b>
          </div>
        </div>
      </div>
    </form>
    <div *ngFor="let data of this.contents">
      <app-added-brain-content [embeddingFile]="data" (deleteContent)="deleteContent($event)"></app-added-brain-content>
    </div>
  </div>

  <div class="lg:hidden">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>
          <div class="flex items-center">
            <i class="w-10 h-10 bg-light-violet rounded-full flex items-center justify-center mr-2 text-black-dark1">
              <img src="./assets/images/icons/globe-violet.svg" />
            </i>
            <span class="text-black-dark1">
              {{ t("addWebAddress") }}
            </span>
            <a href="https://help.rezolve.com/brain/assistant/content-guide/" class="ml-2" target="_blank">
              <img src="./assets/images/icons/question-mark-violet.svg" alt="" />
            </a>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form>
        <div class="bg-light-violet px-3 pt-3 pb-8 rounded-lg min-h-[160px]">
          <div class="relative flex gap-2">
            <mat-form-field appearance="outline" class="h-8 w-full" *transloco="let v; read: 'validation'">
              <mat-label>{{ t("webAddress") }}</mat-label>
              <input matInput [formControl]="webPageUrl" name="webPageUrl" />
              <mat-error *ngIf="webPageUrl.invalid">{{ v("invalidUrl") }}</mat-error>
            </mat-form-field>
            <button
              class="border-none flex items-center justify-center w-[120px] h-[52px] text-violet-semi bg-violet-background rounded-lg text-base"
              brainLimitIndicator
              [limitConfig]="{
                isContentExceeded: userDataLimits.isContentExceeded
              }"
              [disabled]="userDataLimits.isContentExceeded || webPageUrl.invalid || uploading || fileUploading"
              (click)="validateUrl()"
            >
              <span class="inline-flex">
                <img src="./assets/images/icons/plus.svg" alt="" class="mr-2" />
                {{ t("add") }}
              </span>
            </button>
          </div>

          <div class="w-full mt-2" *ngIf="userData?.planLevel !== MyBrainPlans.free">
            <mat-checkbox [(ngModel)]="addAllPages" name="checkbox">{{ t("addAllPages") }}</mat-checkbox>
          </div>
        </div>

        <div>
          <div class="flex justify-between px-2 py-2 text-light-black">
            <div>
              <span>{{ t("webAddresses") }}</span>
            </div>
            <div>
              <span>{{ t("total") }}:</span> <b>{{ this.contents.length }}</b>
            </div>
          </div>
        </div>
      </form>
      <div *ngFor="let data of contents">
        <app-added-brain-content
          [embeddingFile]="data"
          (deleteContent)="deleteContent($event)"
        ></app-added-brain-content>
      </div>
    </mat-expansion-panel>
  </div>
</div>
