import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { HOME_ROUTE, LS_TOKEN } from "app/const/app-constant";

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);

  const token = localStorage.getItem(LS_TOKEN);
  if (token) {
    return true;
  } else {
    return router.createUrlTree([HOME_ROUTE], { queryParams: { returnUrl: state.url } });
  }
};
