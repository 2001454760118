<div *transloco="let t; read: 'addMyBrainContent'" class="flex w-[100%] h-[100%] flex-col">
  <div class="hidden lg:flex lg:min-h-[242px] h-full flex-col p-1">
    <div class="flex flex-row items-center mb-3">
      <i class="w-10 h-10 bg-light-violet rounded-full flex items-center justify-center mr-2 text-black-dark1">
        <img src="./assets/images/icons/file-light-violet.svg" />
      </i>
      <span class="text-black-dark1 text-base">
        {{ t("addFromFile") }}
      </span>
      <a href="https://help.rezolve.com/brain/assistant/content-guide/" class="ml-2" target="_blank">
        <img src="./assets/images/icons/question-mark-violet.svg" alt="question-mark-violet" />
      </a>
    </div>
    <form>
      <div class="bg-light-violet px-3 pt-3 pb-8 rounded-lg">
        <div class="relative">
          <div class="flex bg-white border border-dashed rounded-lg px-4 w-full justify-between">
            <div class="items-center flex">
              <span class="items-center max-w-[187.5px] text-rezolve-light-gray text-base">
                <input
                  type="file"
                  (change)="onFileChange($event)"
                  [multiple]="true"
                  [disabled]="userDataLimits.isContentExceeded || uploadingInProgress || webPageUploading"
                  [accept]="this.filesAccept"
                  class="file-drag"
                />
                {{ t("dragFile") }}
              </span>
            </div>
            <div class="flex items-center text-rezolve-light-gray text-base">{{ t("or") }}</div>
            <div class="items-center mt-2 mb-2">
              <button
                brainLimitIndicator
                [limitConfig]="{
                  isContentExceeded: userDataLimits.isContentExceeded
                }"
                class="border-none pl-4 pr-9 py-3 ml-3 text-violet-semi bg-violet-background rounded-lg text-base"
                [disabled]="userDataLimits.isContentExceeded || uploadingInProgress || webPageUploading"
              >
                <span class="inline-flex">
                  {{ t("selectFile") }}
                  <img src="./assets/images/icons/attach-file-violet.svg" alt="" class="ml-2" />
                </span>
              </button>
            </div>
          </div>
          <div class="red font-normal text-sm text-red-600 mt-2" *ngFor="let em of errorMessage">
            {{ em }}
          </div>
        </div>
        <div class="w-full mt-2">
          <span class="text-xs text-rezolve-light-gray">{{ t("formatAndSize", { types: filesStr }) }}</span>
        </div>
      </div>

      <div>
        <div class="flex justify-between px-2 py-2 text-light-black text-base">
          <div>
            <span>{{ t("files") }}</span>
          </div>
          <div>
            <span>{{ t("total") }}:</span> <b>{{ this.contents.length }}</b>
          </div>
        </div>
      </div>
    </form>
    <div *ngFor="let data of contents">
      <app-added-brain-content [embeddingFile]="data" (deleteContent)="deleteContent($event)"></app-added-brain-content>
    </div>
  </div>

  <div class="lg:hidden">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>
          <div class="flex items-center">
            <i class="w-10 h-10 bg-light-violet rounded-full flex items-center justify-center mr-2 text-black-dark1">
              <img src="./assets/images/icons/file-light-violet.svg" alt="file-light-violet" />
            </i>
            <span class="text-black-dark1">
              {{ t("addFromFile") }}
            </span>
            <a href="https://help.rezolve.com/brain/assistant/content-guide/" class="ml-2" target="_blank">
              <img src="./assets/images/icons/question-mark-violet.svg" alt="" />
            </a>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form>
        <div class="bg-light-violet px-3 pt-3 pb-8 rounded-lg cursor-pointer">
          <div class="relative">
            <input
              type="file"
              (change)="onFileChange($event)"
              #uploadFile
              [multiple]="true"
              [disabled]="userDataLimits.isContentExceeded || uploadingInProgress || webPageUploading"
              [accept]="this.filesAccept"
              class="file-drag"
            />
            <div class="flex bg-white border border-dashed rounded-lg px-4 w-full justify-between">
              <div class="items-center mt-2 mb-2 w-full">
                <button
                  brainLimitIndicator
                  [limitConfig]="{
                    isContentExceeded: userDataLimits.isContentExceeded
                  }"
                  class="border-none py-3 text-violet-semi bg-violet-background rounded-lg w-full"
                  [disabled]="userDataLimits.isContentExceeded || uploadingInProgress || webPageUploading"
                >
                  <span class="inline-flex">
                    {{ t("selectFile") }}
                    <img src="./assets/images/icons/attach-file-violet.svg" alt="" class="ml-2" />
                  </span>
                </button>
              </div>
            </div>
            <div class="red font-normal text-sm text-red-600 mt-2" *ngFor="let em of errorMessage">
              {{ em }}
            </div>
          </div>
          <div class="w-full mt-2">
            <span class="text-xs text-light-black">{{ t("formatAndSize", { types: filesStr }) }}</span>
          </div>
        </div>

        <div>
          <div class="flex justify-between px-2 py-2 text-light-black">
            <div>
              <span>{{ t("files") }}</span>
            </div>
            <div>
              <span>{{ t("total") }}:</span> <b>{{ this.contents.length }}</b>
            </div>
          </div>
        </div>
      </form>
      <div *ngFor="let data of contents">
        <app-added-brain-content
          [embeddingFile]="data"
          (deleteContent)="deleteContent($event)"
        ></app-added-brain-content>
      </div>
    </mat-expansion-panel>
  </div>
</div>
